@import "icon-variables";
@import "animation";
@import "../colors";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?n7utff');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?n7utff#iefix') format('embedded-opentype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?n7utff') format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?n7utff') format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?n7utff##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aihub-icon-"], [class*=" aihub-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aihub-icon-spinner {

    &:before {
        content: $aihub-icon-spinner; 
    }

    font-size: inherit;
    display: inline-block;
    -webkit-animation: rotating 1.5s linear infinite;
    -moz-animation: rotating 1.5s linear infinite;
    -ms-animation: rotating 1.5s linear infinite;
    -o-animation: rotating 1.5s linear infinite;
    animation: rotating 1.5s linear infinite;
}

