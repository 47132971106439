mat-option,
.mat-option {
    margin-bottom: 8px;
    border-left: 4px solid transparent;
    font-family: OpenSansRegular !important;
    padding-left: 1rem !important;
    line-height: 2.1rem !important;
    font-size: 0.9rem !important;
    height: 2.1rem !important;
    &.mat-active {
        background: transparent !important;
        color: $deloitte-green;
        font-weight: bold;
        border-left: 4px solid $deloitte-green;
    }
}
