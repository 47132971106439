/*------------------------
	icons
-------------------------*/
@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

$icon-conference-room-situation: unicode(ea02);
$icon-360: unicode(ea03);
$icon-chip: unicode(ea04);
$icon-app-services: unicode(ea05);
$icon-sync: unicode(ea06);
$icon-artificial-intelligence: unicode(ea07);
$icon-decision-process-1: unicode(ea08);
$icon-cloud: unicode(ea09);
$icon-verified: unicode(ea0a);
$icon-roadmap: unicode(ea0b);
$icon-safe: unicode(ea0c);
$icon-time: unicode(ea0d);
$icon-search: unicode(ea0e);
$icon-timeline-workflow: unicode(ea0f);
$icon-warning: unicode(ea10);
$icon-currency-exchange-2: unicode(ea11);
$icon-c-info: unicode(ea12);
$icon-expand-scale-grow: unicode(ea13);
$icon-tap: unicode(ea14);
$icon-list-bullet: unicode(ea15);
$icon-code: unicode(ea16);
$icon-text: unicode(ea17);
$icon-screen-sharing: unicode(ea18);
$icon-error-finder: unicode(ea19);
$icon-error-check: unicode(ea1a);
$icon-presentation: unicode(ea1b);
$icon-key: unicode(ea1c);
$icon-sound: unicode(ea1d);
$icon-auto-focus: unicode(ea1e);
$icon-options-arrange-filter: unicode(ea1f);
$icon-video-gallery: unicode(ea20);
$icon-save-to-list-add: unicode(ea21);
$icon-journey: unicode(ea22);
$icon-road-2: unicode(ea23);
$icon-square-marker-location: unicode(ea24);
$icon-flag-complex: unicode(ea25);
$icon-target-goal: unicode(ea26);
$icon-bookmark-add-save-for-later: unicode(ea27);
$icon-road-workflow: unicode(ea28);
$icon-refresh-reload: unicode(ea29);
$icon-circles-load-progress: unicode(ea2a);
$icon-alarm: unicode(ea2b);
$icon-document-license-file: unicode(ea2c);
$icon-replace-folder: unicode(ea2d);
$icon-folder-user-manage: unicode(ea2e);
$icon-reload-change: unicode(ea2f);
$icon-trend-curves-analysis: unicode(ea30);
$icon-priority-highest: unicode(ea31);
$icon-mask-overlay: unicode(ea32);
$icon-vector-arrange: unicode(ea33);
$icon-wand-solution: unicode(ea34);
$icon-window-dev-code: unicode(ea35);
$icon-stock-market-manage: unicode(ea36);
$icon-scale: unicode(ea37);
$icon-pin: unicode(ea38);
$icon-laptop-chart-increase: unicode(ea39);
$icon-laptop-graph-dashboard: unicode(ea3a);
$icon-hierarchy-group-organization-chart: unicode(ea3b);
$icon-trend-up-increase-arrow: unicode(ea3c);
$icon-swap-vertical-arrow: unicode(ea3d);
$icon-trend-down-decrease-arrow: unicode(ea3e);
$icon-arrow-unite: unicode(ea3f);
$icon-split-arrow: unicode(ea40);
$icon-divide-horizontal-arrow: unicode(ea41);
$icon-browser-edge: unicode(ea42);
$icon-plug-connect: unicode(ea43);
$icon-edit-levels-dynamic: unicode(ea44);
$icon-decision-process-2: unicode(ea45);
$icon-ungroup: unicode(ea46);
$icon-bulb-idea: unicode(ea47);
$icon-math-calculation: unicode(ea48);
$icon-money-time: unicode(ea49);
$icon-presentation-manage: unicode(ea4a);
$icon-screen-sharing-off: unicode(ea4b);
$icon-arrow-swap-horizontal: unicode(ea4c);
$icon-archive-paper-check: unicode(ea4d);
$icon-files-copies-folder: unicode(ea4e);
$icon-people-explore-user: unicode(ea4f);
$icon-people-position-user-centric: unicode(ea50);
$icon-cut: unicode(ea51);
$icon-pin-patterns: unicode(ea52);
$icon-messaging-configuration: unicode(ea53);
$icon-server: unicode(ea54);
$icon-wireless-charging: unicode(ea55);
$icon-eye-recognition: unicode(ea56);
$icon-unlink: unicode(ea57);
$icon-url: unicode(ea58);
$icon-pattern-recognition: unicode(ea59);
$icon-contactless-signal: unicode(ea5a);
$icon-favorite-list: unicode(ea5b);
$icon-file-add: unicode(ea5c);
$icon-hot-key: unicode(ea5d);
$icon-switches: unicode(ea5e);
$icon-check-all: unicode(ea5f);
$icon-schedule-agenda: unicode(ea60);
$icon-networking: unicode(ea61);
$icon-metrics: unicode(ea62);
$icon-tile-table: unicode(ea63);
$icon-filter: unicode(ea64);
$icon-forward: unicode(ea65);
$icon-fullsize: unicode(ea66);
$icon-sort-tool: unicode(ea67);
$icon-edit: unicode(ea68);
$icon-mail-message: unicode(ea69);
$icon-speed: unicode(ea6a);
$icon-conversation-message: unicode(ea6b);
$icon-chart-column: unicode(ea6c);
$icon-pie-chart: unicode(ea6d);
$icon-backward: unicode(ea6e);
$icon-hotspot: unicode(ea6f);
$icon-chart-table-move: unicode(ea70);
$icon-setting: unicode(ea71);
$icon-people-team: unicode(ea72);
$icon-people-user-list: unicode(ea73);
$icon-people-exchange: unicode(ea74);
$icon-browser-safari-compass: unicode(ea75);
$icon-interaction-click: unicode(ea76);
$icon-connect-network-integration: unicode(ea77);
$icon-chart-pie: unicode(ea78);
$icon-chart-trend-increase: unicode(ea79);
$icon-restore: unicode(ea7a);
$icon-server-rack: unicode(ea7b);
$icon-chart-structure-subsection: unicode(ea7c);
$icon-api: unicode(ea7d);
$icon-alignment: unicode(ea7e);
$icon-license: unicode(ea7f);
$icon-divider: unicode(ea80);
$icon-animation-jump: unicode(ea81);
$icon-3d-room: unicode(ea82);

.icon-conference-room-situation::before {
  content: $icon-conference-room-situation;
}

.icon-360::before {
  content: $icon-360;
}

.icon-chip::before {
  content: $icon-chip;
}

.icon-app-services::before {
  content: $icon-app-services;
}

.icon-sync::before {
  content: $icon-sync;
}

.icon-artificial-intelligence::before {
  content: $icon-artificial-intelligence;
}

.icon-decision-process-1::before {
  content: $icon-decision-process-1;
}

.icon-cloud::before {
  content: $icon-cloud;
}

.icon-verified::before {
  content: $icon-verified;
}

.icon-roadmap::before {
  content: $icon-roadmap;
}

.icon-safe::before {
  content: $icon-safe;
}

.icon-time::before {
  content: $icon-time;
}

.icon-search::before {
  content: $icon-search;
}

.icon-timeline-workflow::before {
  content: $icon-timeline-workflow;
}

.icon-warning::before {
  content: $icon-warning;
}

.icon-currency-exchange-2::before {
  content: $icon-currency-exchange-2;
}

.icon-c-info::before {
  content: $icon-c-info;
}

.icon-expand-scale-grow::before {
  content: $icon-expand-scale-grow;
}

.icon-tap::before {
  content: $icon-tap;
}

.icon-list-bullet::before {
  content: $icon-list-bullet;
}

.icon-code::before {
  content: $icon-code;
}

.icon-text::before {
  content: $icon-text;
}

.icon-screen-sharing::before {
  content: $icon-screen-sharing;
}

.icon-error-finder::before {
  content: $icon-error-finder;
}

.icon-error-check::before {
  content: $icon-error-check;
}

.icon-presentation::before {
  content: $icon-presentation;
}

.icon-key::before {
  content: $icon-key;
}

.icon-sound::before {
  content: $icon-sound;
}

.icon-auto-focus::before {
  content: $icon-auto-focus;
}

.icon-options-arrange-filter::before {
  content: $icon-options-arrange-filter;
}

.icon-video-gallery::before {
  content: $icon-video-gallery;
}

.icon-save-to-list-add::before {
  content: $icon-save-to-list-add;
}

.icon-journey::before {
  content: $icon-journey;
}

.icon-road-2::before {
  content: $icon-road-2;
}

.icon-square-marker-location::before {
  content: $icon-square-marker-location;
}

.icon-flag-complex::before {
  content: $icon-flag-complex;
}

.icon-target-goal::before {
  content: $icon-target-goal;
}

.icon-bookmark-add-save-for-later::before {
  content: $icon-bookmark-add-save-for-later;
}

.icon-road-workflow::before {
  content: $icon-road-workflow;
}

.icon-refresh-reload::before {
  content: $icon-refresh-reload;
}

.icon-circles-load-progress::before {
  content: $icon-circles-load-progress;
}

.icon-alarm::before {
  content: $icon-alarm;
}

.icon-document-license-file::before {
  content: $icon-document-license-file;
}

.icon-replace-folder::before {
  content: $icon-replace-folder;
}

.icon-folder-user-manage::before {
  content: $icon-folder-user-manage;
}

.icon-reload-change::before {
  content: $icon-reload-change;
}

.icon-trend-curves-analysis::before {
  content: $icon-trend-curves-analysis;
}

.icon-priority-highest::before {
  content: $icon-priority-highest;
}

.icon-mask-overlay::before {
  content: $icon-mask-overlay;
}

.icon-vector-arrange::before {
  content: $icon-vector-arrange;
}

.icon-wand-solution::before {
  content: $icon-wand-solution;
}

.icon-window-dev-code::before {
  content: $icon-window-dev-code;
}

.icon-stock-market-manage::before {
  content: $icon-stock-market-manage;
}

.icon-scale::before {
  content: $icon-scale;
}

.icon-pin::before {
  content: $icon-pin;
}

.icon-laptop-chart-increase::before {
  content: $icon-laptop-chart-increase;
}

.icon-laptop-graph-dashboard::before {
  content: $icon-laptop-graph-dashboard;
}

.icon-hierarchy-group-organization-chart::before {
  content: $icon-hierarchy-group-organization-chart;
}

.icon-trend-up-increase-arrow::before {
  content: $icon-trend-up-increase-arrow;
}

.icon-swap-vertical-arrow::before {
  content: $icon-swap-vertical-arrow;
}

.icon-trend-down-decrease-arrow::before {
  content: $icon-trend-down-decrease-arrow;
}

.icon-arrow-unite::before {
  content: $icon-arrow-unite;
}

.icon-split-arrow::before {
  content: $icon-split-arrow;
}

.icon-divide-horizontal-arrow::before {
  content: $icon-divide-horizontal-arrow;
}

.icon-browser-edge::before {
  content: $icon-browser-edge;
}

.icon-plug-connect::before {
  content: $icon-plug-connect;
}

.icon-edit-levels-dynamic::before {
  content: $icon-edit-levels-dynamic;
}

.icon-decision-process-2::before {
  content: $icon-decision-process-2;
}

.icon-ungroup::before {
  content: $icon-ungroup;
}

.icon-bulb-idea::before {
  content: $icon-bulb-idea;
}

.icon-math-calculation::before {
  content: $icon-math-calculation;
}

.icon-money-time::before {
  content: $icon-money-time;
}

.icon-presentation-manage::before {
  content: $icon-presentation-manage;
}

.icon-screen-sharing-off::before {
  content: $icon-screen-sharing-off;
}

.icon-arrow-swap-horizontal::before {
  content: $icon-arrow-swap-horizontal;
}

.icon-archive-paper-check::before {
  content: $icon-archive-paper-check;
}

.icon-files-copies-folder::before {
  content: $icon-files-copies-folder;
}

.icon-people-explore-user::before {
  content: $icon-people-explore-user;
}

.icon-people-position-user-centric::before {
  content: $icon-people-position-user-centric;
}

.icon-cut::before {
  content: $icon-cut;
}

.icon-pin-patterns::before {
  content: $icon-pin-patterns;
}

.icon-messaging-configuration::before {
  content: $icon-messaging-configuration;
}

.icon-server::before {
  content: $icon-server;
}

.icon-wireless-charging::before {
  content: $icon-wireless-charging;
}

.icon-eye-recognition::before {
  content: $icon-eye-recognition;
}

.icon-unlink::before {
  content: $icon-unlink;
}

.icon-url::before {
  content: $icon-url;
}

.icon-pattern-recognition::before {
  content: $icon-pattern-recognition;
}

.icon-contactless-signal::before {
  content: $icon-contactless-signal;
}

.icon-favorite-list::before {
  content: $icon-favorite-list;
}

.icon-file-add::before {
  content: $icon-file-add;
}

.icon-hot-key::before {
  content: $icon-hot-key;
}

.icon-switches::before {
  content: $icon-switches;
}

.icon-check-all::before {
  content: $icon-check-all;
}

.icon-schedule-agenda::before {
  content: $icon-schedule-agenda;
}

.icon-networking::before {
  content: $icon-networking;
}

.icon-metrics::before {
  content: $icon-metrics;
}

.icon-tile-table::before {
  content: $icon-tile-table;
}

.icon-filter::before {
  content: $icon-filter;
}

.icon-forward::before {
  content: $icon-forward;
}

.icon-fullsize::before {
  content: $icon-fullsize;
}

.icon-sort-tool::before {
  content: $icon-sort-tool;
}

.icon-edit::before {
  content: $icon-edit;
}

.icon-mail-message::before {
  content: $icon-mail-message;
}

.icon-speed::before {
  content: $icon-speed;
}

.icon-conversation-message::before {
  content: $icon-conversation-message;
}

.icon-chart-column::before {
  content: $icon-chart-column;
}

.icon-pie-chart::before {
  content: $icon-pie-chart;
}

.icon-backward::before {
  content: $icon-backward;
}

.icon-hotspot::before {
  content: $icon-hotspot;
}

.icon-chart-table-move::before {
  content: $icon-chart-table-move;
}

.icon-setting::before {
  content: $icon-setting;
}

.icon-people-team::before {
  content: $icon-people-team;
}

.icon-people-user-list::before {
  content: $icon-people-user-list;
}

.icon-people-exchange::before {
  content: $icon-people-exchange;
}

.icon-browser-safari-compass::before {
  content: $icon-browser-safari-compass;
}

.icon-interaction-click::before {
  content: $icon-interaction-click;
}

.icon-connect-network-integration::before {
  content: $icon-connect-network-integration;
}

.icon-chart-pie::before {
  content: $icon-chart-pie;
}

.icon-chart-trend-increase::before {
  content: $icon-chart-trend-increase;
}

.icon-restore::before {
  content: $icon-restore;
}

.icon-server-rack::before {
  content: $icon-server-rack;
}

.icon-chart-structure-subsection::before {
  content: $icon-chart-structure-subsection;
}

.icon-api::before {
  content: $icon-api;
}

.icon-alignment::before {
  content: $icon-alignment;
}

.icon-license::before {
  content: $icon-license;
}

.icon-divider::before {
  content: $icon-divider;
}

.icon-animation-jump::before {
  content: $icon-animation-jump;
}

.icon-3d-room::before {
  content: $icon-3d-room;
}

