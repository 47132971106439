@import './theming';
@import './colors.scss';
@import './icons/icons.scss';


*,
*::before,
*::after {
    box-sizing: border-box; // 1
}

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * .9 !default;
$font-size-xlg: $font-size-base * 1.5 !default;
$font-size-xsm: $font-size-base * .75 !default;
$font-size-xxlg: $font-size-base * 1.8 !default;
$font-size-xxsm: $font-size-base * .6 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$primary: $blue3 !default;
$secondary: $cool-gray7;
$success: $green4;
$danger: $red;
$warning: $yellow;
$info: $teal5;
$light: $white;
$dark: $black;

$link-color: $blue3;
$link-color-hover: $blue4;

$breadcrumb-bg: $white;
$breadcrumb-divider-color: $cool-gray7;
$breadcrumb-active-color: $cool-gray11;

// Material Custom Design
$brand-blue: (
  50: $blue1,
  100: $blue2,
  200: $blue3,
  300: $blue3,
  400: $blue3,
  500: $blue3,
  600: $blue4,
  700: $blue5,
  800: $blue6,
  A200: $blue2,
  A400: $blue3,
  A700: $blue5,
  contrast: (
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A700: white,
));

$brand-green: (
  50: $blue1,
  100: $deloitte-green,
  200: $deloitte-green,
  300: $deloitte-green,
  400: $deloitte-green,
  500: $deloitte-green,
  600: $deloitte-green,
  700: $deloitte-green,
  800: $deloitte-green,
  A200: $deloitte-green,
  A400: #3778ff,
  A700: #1e67ff,
  contrast: (500: white,
  600: white,
  700: white,
  800: white,
  900: white,
  A700: white,
));

@include mat-core();

// mandatory stuff for theming
$primary-palette: mat-palette($brand-blue);
$accent-palette: mat-palette($brand-green);
//$ae-warn-palette: mat-palette(red);

// include the custom theme components into a theme object
$main-theme: mat-light-theme($primary-palette, $accent-palette);

//select primary and secondary colors
$primary-color: map_get($primary-palette, 500);
$secondary-color: map_get($accent-palette, 500);
$foreground: $cool-gray7;

// include the custom theme object into the angular material theme
@include angular-material-theme($main-theme);
@import "bootstrap/scss/bootstrap.scss";
@import "bootstrap/scss/bootstrap-grid.scss";

@import "./_theme/typography.scss";
@import "./_theme/button";
@import "./_theme/dialog";
@import "./_theme/badge";
@import "./_theme/lists.scss";
@import "./_theme/headers.scss";
@import "./_theme/mat-mdc.scss";

html, body {
  height: 100%;
}

html {
    scroll-behavior: smooth;
}

body {

    @media(max-width: 1400px) {
        font-size: 16px
    }
    span,a :focus{outline: none!important;}

    font-size: $font-size-base;
    color: black;
    overflow-x: hidden;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    .container{
        margin:0;
        padding:0;
    }

    .container-landing{
        width:60%;
        margin: 0 auto;
    }
    .main-container{
        width:100vw;
    }

    app-footer{
        width:100vw !important;
    }
   .carousel-indicators li{
       height:1rem;
       width:1rem;
       border-radius:50%;
   }

    a{
        font-weight: bold;
        :hover{
            text-decoration: underline;
        }
    }
    a.nav-link {
        color: $blue3;

        &:hover {
            color: darken($blue3, 15%)
        }
    }

    .navbar {
        margin:0!important;
        background-color: $black;
        color: $white;

        .navbar-brand {
            color: $white;
            font-weight: 300;
            margin-right: 48px;

            &:hover,
            &:active {
                color: darken($blue3, 15%)
            }
        }

        .nav-item {
            &:hover {
                background: darken($cool-gray9, 20%);
            }
        }
    }

    h1 {
        color: $black;
        font-weight: $font-weight-light;
    }

    h5 {
        color: $blue3;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.15rem;
    }


    .card {
        .card-summary {
            background: $blue3;
            border-radius: 1rem;
            color: $white;
            padding: 0.3rem 0.7rem !important;
        }

        .card-footer {
            padding: 1.25rem;
        }
    }

    .text- {
        &green {
            color: $deloitte-green;
        }

        &blue {
            color: $blue3;
        }

        &red {
            color: $danger;
        }

        &yellow {
            color: $yellow;
        }

        &grey {
            color: $cool-gray7;
        }

        &black {
            color: $black;
        }
        &white {
            color:$white;
        }
    }

    .bg {
        &-green {
            background: $deloitte-green;
        }

        &-blue {
            background: $blue3;
        }

        &-grey {
            background: $cool-gray7;
        }

        &-light-grey {
            background: rgba(0, 0, 0, 0.12);
        }

        &-black {
            background: #000;
        }
    }


    .text-underline {
        text-decoration: underline;
    }

    .font-size-lg {
        font-size: $font-size-lg;
    }

    .font-size-sm {
        font-size: $font-size-sm;
    }


    a{

        :hover{
            text-decoration: underline;
        }
    }

    footer {
        width:100vw;
        a {
            color: $cool-gray7;
        }

        a:hover {
            color: $cool-gray9;
            text-decoration: none;
        }
    }

    .mat-form-field-infix{
        line-height: 1.5rem!important;
    }

    .mat-table {
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 0;

        .mat-header-cell {
            font-size: $font-size-sm;
            font-weight: bold;
        }

        .mat-header-row {
            min-height: 48px;
        }
    }

    .material-icons {
        font-size: 1rem;
        vertical-align: middle;
        height: 1rem;
        width: 1.2rem;
        margin: 0 0.3rem;

    }

    .flag-icon {
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        position: relative;
        display: inline-block;
        width: 1.33333333em;
        line-height: 1em;

        &:before {
            content: "\00a0";
        }

        &-en {
            background-image: url('../flags/us.svg');
        }

        &-de {
            background-image: url('../flags/de.svg');
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .mat-menu-panel {
        border-radius: 0;
    }

    .breadcrumb {
        padding: 1rem 0;
        list-style: none;
        background-color: transparent;

        li {
            display: inline;

            a {
                color: #000;
                font-weight: bold;
            }
        }
    }

    .success {
        background: $deloitte-green;
        color: $white;
    }

    .error {
        background: $red;
        color: $white;
    }

    .mt- {
        &50 {
            margin-top: -50px;
        }

        &100 {
            margin-top: -100px;
        }

        &150 {
            margin-top: -150px;
        }
    }

    .pt- {
        &50 {
            padding-top: 50px;
        }

        &100 {
            padding-top: 100px;
        }

        &150 {
            padding-top: 150px;
        }
    }

    .pb- {
        &50 {
            padding-bottom: 50px;
        }

        &100 {
            padding-bottom: 100px;
        }

        &150 {
            padding-bottom: 150px;
        }

        &6{
            padding-bottom: 5rem;
        }

        &7 {
            padding-bottom: 10rem;
        }
    }

    .border-2 {
        border: 2px solid $blue3;
    }

    .btn.active {
        // background: $cool-gray2 !important;
        border-width: 5px !important;
    }

    .btn.inactive {
        // background: transparent !important;
        border-width: 2px !important;
    }

    .pointer {
      cursor: pointer;
    }

    .mat-progress-spinner.spinner-white, .mat-spinner.spinner-white {
      circle {
        stroke: $white !important;
      }
    }
    .mat-input-element{
        font-size:1rem;
    }
    //Change toggle button color to brand based
    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
        background-color:rgba(98,181,229, 0.5) !important;

    }
    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
        background-color: rgb(98, 181, 229) !important;
    }
    //Dropdown get rid of shadows
    .mat-select-panel:not([class*=mat-elevation-z]) {
        box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
    }
    .mat-select-arrow{
        margin-bottom:5px;
    }
    .mat-checkbox {
        &:hover{
            background-color:white;
        }
        .mat-ripple-element{
            transition-duration: 0ms;
            transition:none;
        }
        .mat-checkbox-frame {
         border: 1px solid rgba(1, 1, 1, 0.54);
         border-radius: 0.1rem;
        }
        .mat-checkbox-inner-container:hover{
            transition:none;
        }

        &.mat-checkbox-checked .mat-checkbox-background {
          background-color: rgb(98, 181, 229);
          border: 1px solid rgb(98, 181, 229);
          color:white;
        }
        .mat-checkbox-anim-unchecked-checked {
            transition:none;
            transition-duration: 0ms;
        }
    }
    .button-icon-container {
      display: flex;
      width: 24px;
    }

    .mat-expansion-panel-header {
        padding-left: 0px;
        padding-right: 24px;
    }

    .mat-expansion-panel-body {
        padding: 0px !important;
    }

    .multiselect{
        .selected-list{
            width:18rem!important;
        }
        .list-area{
            width:18rem!important;
            position: relative;
            top: 108px;
        }
    }

    .deloitte-tooltip {
        border: 0.1rem solid $deloitte-green;
        padding: 1rem;
        font-size: $font-size-sm;
        color: black;
        background-color: white;
    }


}
// style for container that are used on the landing page
.container {
    margin-left: 12 rem;
    margin-right: 12 rem;
}


.border-green{
    border-bottom: 0.5rem solid $deloitte-green;
}

// For Mobile
@media(max-width: 550px) {

    .container-landing{
        width:90%;
        margin: 0 auto;
    }
    .text-right {
        text-align: left;
    }

    .p-xs-0 {
        padding: 0
    }
    .services{
        width:100%;
    }
}
@media (min-width:551px) and (max-width:800px){
    .container-landing{
        width:80%;
        margin: 0 auto;
    }
}
@media(min-width: 768px) {
    .services{
        margin:0 auto;
        width:70%;
    }
  .mt-md- {
    &50 {
        margin-top: -50px;
    }

    &100 {
        margin-top: -100px;
    }

    &150 {
        margin-top: -150px;
    }
}

}
@media(min-width: 1400px) {
    .services{
        width:70%;
        margin:0 auto;
    }
}
@media(max-width:1400px){

}

#container-content {
    padding-top: 75px;
}

// Header
header {
  .header-logo {
      height: 44px;
  }

  .navbar {
      padding: 0 0 0 1rem;
      height: 4.375rem;

      .navbar-nav {
          height: 4.375rem;
          flex-direction: row !important;

          .static-nav-item {
              font-size: 1rem;
              line-height: 4.5;
              padding: 0 1rem;
          }
       .navbar-text {
        padding-top: 1.3rem;
        }
      }
  }

  .show{
      display: block;
  }


}
.mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

// Dropdown Multiple Select
.c-btn {
    background: #fff;
    border: 1px solid #97999B;
    color: #97999B;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    padding-top: 13.5px !important;
    height: 2.85rem;
    border-radius: 0px !important;
}

  .selected-list .c-list .c-token {
    background: #00A3E0; }
  .selected-list .c-list .c-token .c-label {
      color: #fff; }
  .selected-list .c-list .c-token .c-remove svg {
      fill: #fff; }

  .selected-list .c-angle-down svg, .selected-list .c-angle-up svg {
    fill: #333; }

  .dropdown-list ul li:hover {
    background: #f5f5f5;
    }

   .dropdown-list ul li{
        border-top: 0.5px solid #97999B;
    }

   .dropdown-list {
    padding-top: 0px !important;
    }


   // disable the displaying of the arrow above the list
  .arrow-up, .arrow-down {
      border:none !important;
      margin-left:0;
      }
   // disable the displaying of the arrow above the list
  .arrow-2 {
    border-bottom: none; }

  .list-area {
    border: 1px solid #97999B;
    border-top: hidden;
    background: #fff;
    box-shadow: 0px 1px 5px #97999B; }

  .select-all {
    border-bottom: 1px solid #97999B; }

  .list-filter {
    border-bottom: 1.5px solid #97999B !important; }
    .list-filter .c-search svg {
      fill: #97999B; }
    .list-filter .c-clear svg {
      fill: #97999B; }

  .pure-checkbox input[type="checkbox"]:focus + label:before, .pure-checkbox input[type="checkbox"]:hover + label:before {
    border-color: #00A3E0;
    background-color: #f2f2f2; }

  .pure-checkbox input[type="checkbox"] + label {
    color: #000; }

  .pure-checkbox input[type="checkbox"] + label:before {
    color: #00A3E0;
    border: 1px solid #00A3E0; }

  .pure-checkbox input[type="checkbox"] + label:after {
    background-color: #00A3E0; }

  .pure-checkbox input[type="checkbox"]:disabled + label:before {
    border-color: #97999B; }

  .pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
    background-color: #97999B; }

  .pure-checkbox input[type="checkbox"] + label:after {
    border-color: #ffffff; }

  .pure-checkbox input[type="radio"]:checked + label:before {
    background-color: white; }

  .pure-checkbox input[type="checkbox"]:checked + label:before {
    background: #00A3E0; }

  .single-select-mode .pure-checkbox input[type="checkbox"]:focus + label:before, .single-select-mode .pure-checkbox input[type="checkbox"]:hover + label:before {
    border-color: #00A3E0;
    background-color: #f2f2f2; }

  .single-select-mode .pure-checkbox input[type="checkbox"] + label {
    color: #000; }

  .single-select-mode .pure-checkbox input[type="checkbox"] + label:before {
    color: transparent !important;
    border: 0px solid #00A3E0; }

  .single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
    background-color: transparent !important; }

  .single-select-mode .pure-checkbox input[type="checkbox"]:disabled + label:before {
    border-color: #97999B; }

  .single-select-mode .pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
    background-color: #97999B; }

  .single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
    border-color: #00A3E0; }

  .single-select-mode .pure-checkbox input[type="radio"]:checked + label:before {
    background-color: white; }

  .single-select-mode .pure-checkbox input[type="checkbox"]:checked + label:before {
    background: none !important; }

  .btn-iceblue {
    background: #00A3E0;
    border: 1px solid #97999B;
    color: #fff; }
    // dropdown menu should have the same length as mat-form-field
    .cuppa-dropdown {
        width: 180px !important;
    }
 // styles for the badge of the dropdown menu
    .clear-all, .countplaceholder {
        top:55% !important;
        font-size: 1rem !important;
    }


  //Carousel in storefront
  ngb-carousel,.carousel{
      outline:none!important;
  }
@media(max-width:1200px){
    #appName{
        display:none;
    }
}

.w-xs-100 {
  @include media-breakpoint-only(xs) {
    width: 100%;
  }
}
