.henkel-theme {
    background: #fff !important;

    aihub-loader {
        .loader-overlay .loader:before {
            background-color: #cfcfcf !important;
        }
    }

    .filter-tabs-wrapper {
        display: none !important;
    }

    .autocomplete-search-wrapper {
        margin-top: 3rem;
    }

    .navbar {
        background-color: #fff;
        box-shadow: 0 0.2rem 3.2rem rgba(0, 0, 0, .04), 0 0.2rem 1.2rem rgba(0, 0, 0, .04);

        .navbar-nav .static-nav-item {
            color: #fff !important;
        }

        .nav-item:hover {
            background: transparent;
        }

        .header-logo {
            height: auto !important;
            width: 100px !important;
            margin-left: 2rem;
        }
    }

    .shared-header .mat-menu-trigger.active {
        background: transparent !important;
    }

    .bg-black,
    .catalogue__header,
    .brain-asset-detail .header {
        background: #fff !important;
    }

    .autocomplete-search-wrapper {
        border: 1px solid #e1000f !important;
    }

    brain-global-header {
        .tile {
            box-shadow: none;
        }

        .asset-container {
            max-height: 350px;
        }
    }

    .catalogue__header--title,
    .catalogue__header--subtitle,
    .brain-asset-detail .header-title,
    .brain-asset-detail .header-subtitle,
    .brain-asset-detail .header aihub-breadcrumb .list.breadcrumb-list .breadcrumb-list--item a.router-link-active {
        color: #000 !important;

        p>img {
            display: none;
        }
    }

    .catalogue__header {
        padding-bottom: 0px !important;
    }

    .catalog_image {
        margin-top: 3rem;
    }

    .border-green {
        border-bottom: 1px solid #c7cacc;
    }

    .badge.success,
    .btn.btn-secondary,
    a,
    .available-items .mat-chip {
        border-color: #000 !important;
    }

    .deloitteai--header__list .counter {
        background-color: transparent !important;
        border: 1px solid #000 !important;
    }

    .btn.btn-primary:disabled,
    .btn.btn-primary {
        color: #fff !important;
        background-color: #e1000f;
    }


    asset-owner--menu li .label:before,
    .deloitteai__header--usermenu .user {
        color: #fff !important;
        background-color: #e1000f !important;
    }

    .aihub-tooltip mat-icon,
    .badge.success,
    .btn.btn-secondary,
    a,
    .deloitteai--header__list .counter,
    .available-items .mat-chip {
        color: #000 !important;
    }

    footer {
        background-color: #e1000f;
    }

    footer a {
        color: #fff !important;
    }

    .deloitteai__header--usermenu ul {
        background: #fff;
    }

    .hamburger-logo {
        width: 2rem;
        margin-right: 2rem;
    }

    .aihub__form [type=number]:focus,
    .ai-hub-form input[type=password]:focus,
    .ai-hub-form input[type=text]:focus,
    .aihub__form [type=number]:focus,
    .aihub__form [type=password]:focus,
    .aihub__form [type=text]:focus {
        border-color: #e1000f !important;
        box-shadow: 0 0 10px #e1000f;
    }

    .ai-hub-form input[type=radio]+span:before,
    .aihub__form input[type=checkbox]+span:before {
        border-color: #e1000f;
    }

    .btn.btn-secondary {
        border-color: #e1000f !important;

        &:hover {
            background-color: rgb(255, 87, 3, 0.25) !important;
        }
    }

    .ai-hub-form input[type=radio]:checked+span:after,
    .mat-chip-list-wrapper .mat-chip,
    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
    .aihub__form input[type=checkbox]:checked+span:after,
    .aihub__form input[type=checkbox]:checked+span:before {
        background-color: #e1000f !important;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: rgb(255, 87, 3, 0.3) !important;
    }

    .icon-hint {
        border-top-color: #e1000f !important;
    }

    .stepper__segment.completed,
    .stepper__segment.active {
        fill: #fff !important;
        stroke: #e1000f !important;
    }

    .available-items .mat-chip {
        background: #fff !important;
    }

    .btn.prev,
    .btn-bar .btn.action-btn {
        color: #e1000f !important;
    }
}
