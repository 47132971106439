@import "./app-icons";
@import "./mixins";
@import "projects/shared/css/utils/rem-calc";;
@import "./colors";

::ng-deep {
    .btn-bar {

        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: flex-end;

        .btn.action-btn {
            font-size: rem-calc(14);
            font-weight: 700;
            color: $blue;
            height: 2rem;
            align-self: flex-end;

            &.add {
                @include serviceOwnerButton("add", $iconAlignLeft, $blue, cover);
            }

            &.create {
                @include serviceOwnerButton("create", $iconAlignLeft, $blue, cover);
            }

            &.cancel {
                @include serviceOwnerButton("cancel", $iconAlignLeft, $blue, cover);
            }

            &.preview {
                @include serviceOwnerButton("preview", $iconAlignLeft, $blue, cover);
            }
            
            &.add,
            &.cancel,
            &.create {
                border: 0;
                padding: 0;
            }
        }
    }

    .btn-bar.btn-bar-small {

        .btn {
            padding: 0;
        }

        .btn:not(:first-child) {
            margin-left: rem-calc(10px);
        }
    }
}
