$fontRootPath: '/assets/fonts/';

@font-face {
  font-family: OpenSansRegular;
  src: url($fontRootPath + 'OpenSans-Regular.ttf')  format('truetype'),
       url($fontRootPath + 'OpenSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url($fontRootPath + 'OpenSans-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url($fontRootPath + 'OpenSans-Regular.svg#openSansRegular') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: OpenSansSemibold;
  src: url($fontRootPath + 'OpenSans-Semibold.ttf')  format('truetype'),
       url($fontRootPath + 'OpenSans-Semibold.woff2') format('woff2'), /* Super Modern Browsers */
       url($fontRootPath + 'OpenSans-Semibold.woff') format('woff'), /* Pretty Modern Browsers */
       url($fontRootPath + 'OpenSans-Semibold.svg#openSansSemibold') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: OpenSansBold;
  src: url($fontRootPath + 'OpenSans-Bold.ttf')  format('truetype'),
       url($fontRootPath + 'OpenSans-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url($fontRootPath + 'OpenSans-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url($fontRootPath + 'OpenSans-Bold.svg#openSansBold') format('svg'); /* Legacy iOS */
}
