@import "../utils/rem-calc";


$lineheight-dt: rem-calc(19px);
$lineheight-dd: rem-calc(24px);

/** definition lists */
dl {

  dt {
    font-family: OpenSansRegular;
    font-size: rem-calc(14px);
    line-height: rem-calc(19px);
    margin-bottom: rem-calc(6px);
  }

  dd {
    font-family: OpenSansBold;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
  }

  dd:not(:last-child) {
    margin-bottom: rem-calc(30px);
  }

  &.dl__horizontal {

    display: flex;
    flex-direction: column;
    max-height: calc(#{$lineheight-dt} + #{$lineheight-dd} + #{rem-calc(6px)});

    dd {
      margin-bottom: 0;
      margin-right: rem-calc(20px);
    }
  }
}

@mixin horizontal-definition-list() {
  display: flex;
  flex-direction: column;
  max-height: calc(#{$lineheight-dt} + #{$lineheight-dd} + #{rem-calc(6px)});

  dd {
    margin-bottom: 0;
    margin-right: rem-calc(20px);
  }
}
