@import "../utils/rem-calc";
@import "../colors";

.badge {
  border-radius: unset;
  background: inherit !important;
  text-transform: uppercase;
  font-size: rem-calc(12px);
  font-family: OpenSansBold;
 
}

.badge-danger {
  border: 1px solid $red;
  color: $red;
}

.badge.success,
.badge-success {
  border: 1px solid $deloitte-green;
  color: $deloitte-green;
}
