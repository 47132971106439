@import "../colors";

// This is a mdc stylesheet which is required for components post angular 17 upgrade
// This will be replaced by angular mdc theme once angular is upgraded to v18

.item-form-control {
    .mat-mdc-standard-chip .mdc-evolution-chip__ {
        &action--primary {
            &::before {
                background: $deloitte-green;
                color: $white !important;
            }
        }

        &icon--trailing {
            color: $white !important;
            z-index: 1;
            padding-left: 0 !important;
        }

        &text-label {
            color: $white !important;
        }
    }

    input.mat-mdc-chip-input {
        border: 0;
        outline: 0;
        flex: 1 0 10px !important;
    }
}

.mat-mdc- {
    &paginator-container {
        background: $white !important;

        .mdc-notched-outline__trailing {
            border-left: 1px solid $black;
            border-width: 0 0 1px 0 !important;
        }

        .mdc-notched-outline__leading {
            border: 0 !important;
        }
    }

    &slide-toggle .mdc-switch:enabled .mdc-switch__track::after,
    &slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
        background: $deloitte-green !important;
    }

    &standard-chip .mdc-evolution-chip__ {
        &action--primary {
            color: $deloitte-green;
            font-size: 0.9rem !important;
            padding-top: 5px;
            padding-bottom: 5px;

            &::before {
                border-color: var(--mdc-chip-outline-color);
                border: 1px solid $deloitte-green !important;
                color: $deloitte-green;
                border-radius: 16px !important;
            }
        }
    }

    &table {
        border: 1px solid $grey2 !important;
    }

    &header-cell {
        font-size: .9rem;
        border-color: $grey2 !important;
        color: #0000008a;
        background: $white !important;
    }

    &cell {
        border-color: $grey2 !important;
        font-size: .9rem;
        background: $white !important;
    }

    &checkbox {
        text-align: left;

        &__background {
            border-width: 1px !important;
        }

        label {
            margin: 0 !important;
        }

        &.checklist-leaf-node {
            margin-left: 1rem !important;
        }
    }

    &checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        background: $deloitte-green !important;
        border-color: $deloitte-green !important;
    }

    &dialog-content {
        padding: 0;
        margin: 0;
        position: relative;
        color: $black !important;
        line-height: 1.5rem !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
        font-family: $font-family-base !important;

        .header--close {
            position: sticky;
            cursor: pointer;
            top: 0;
        }
    }

    &dialog-title {
        text-align: right;
    }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $deloitte-green !important;
}

.cdk-overlay-pane {
    background: $white;

    .mat-mdc-autocomplete-panel,
    .mat-mdc-select-panel {
        box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f !important;

        .box-select-all {
            text-align: left;
            width: 100%;

            .mdc-checkbox__background {
                border-color: #0000008A !important;
            }

            .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
                background: $deloitte-green;
                border-color: $deloitte-green !important;
            }
        }

        .mat-mdc-option {
            min-height: 30px !important;
        }
    }

    .box-clear-icon .mdc-icon-button {
        padding: 0 !important;
        height: auto;
    }
}